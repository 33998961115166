import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import HeaderNotifications from './Notification';

import Lindex from './Language';
import Profile from './profile';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchComponent from './Search';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import { Badge, useMediaQuery } from '@mui/material';
import { loginRequest } from '../../authConfig';
import { callMsGraph } from '../../graph';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Params, useLocation } from 'react-router-dom';
import { routesAdapter } from '../../configs/routes';
import { useTranslation } from 'react-i18next';
import { getAllNotifications } from '../../Services/ApiService';

export default function PrimarySearchAppBar({ open, onOpenChange }: any) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState();
  const [title, setPageTitle] = useState('');

  const currLocations = useLocation();
  const paths = routesAdapter();

  useEffect(() => {
    const currTitle = paths.find((title: any) => title.path === currLocations.pathname);
    if (currTitle && currTitle)
      setPageTitle(currTitle.label)
  }, [currLocations, paths]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getAllNotes = async () => {
    return await
      getAllNotifications().then((response: any) => {
      
        const allNotifications = response;
        setNotifications(allNotifications)

      })
        .catch(error => console.error(`Error: ${error}`))
  }

  useEffect(() => {
    getAllNotes();
  }, []);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} id={menuId} keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={isMenuOpen} onClose={handleMenuClose}
    >
      <MenuItem onClick={() => handleLogout("redirect")} key="logoutRedirect" style={{ color: "#00005a" }}>Log Out</MenuItem>

    </Menu>
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (

    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <Profile />
        <Typography variant="subtitle1" style={{ color: '#000000', marginRight: 30 }}> {graphData} </Typography>
      </MenuItem>
      <Divider />
      <MenuItem>
        <Lindex />
      </MenuItem>
      <MenuItem>

        <HeaderNotifications />
        <p>Notifications</p>
      </MenuItem>

    </Menu>
  );
  const openL = Boolean(anchorEl);

  const handleLogout = (logoutType: string) => {
    setAnchorEl(null);

    if (logoutType === "popup") {
      instance.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  } 
  const profile = () => {
    instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] }).then((response: any) => {
      callMsGraph(response.accessToken).then((response: any) => {
        setGraphData(response.surname)
      });
    });
  }

  useEffect(() => { profile() }, []);

  return (
    // <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" style={{ background: '#E9EAEF' }}>
      <Toolbar>
        <IconButton onClick={() => onOpenChange(!open)} size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }}>
          <MenuIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }} style={{ color: '#000000' }}>
          {t(title)}
        </Typography>

        {/* <SearchComponent/> */}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Profile />
          <Typography variant="subtitle1" style={{ color: '#000000', marginTop: 22, marginRight: 40 }}> {graphData} </Typography>
          <Divider orientation="vertical" variant="middle" style={{ borderBottomWidth: 4, marginRight: 10 }} flexItem />

          <Lindex />
          <HeaderNotifications />
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit">
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </AppBar>

    // </Box>
  );
}
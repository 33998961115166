import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { UploadImages } from "./UploadImages";
import { toArray } from "lodash";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { deleteImage } from "../../Services/ApiService";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import { useStyle } from "../../styles/styles";


const Card = ({ src, title, id, index, moveImage }:any) => {
  const ref = useRef<HTMLInputElement>(null);;

  const [, drop] = useDrop({
    accept: "image",
    hover: (item:any, monitor:any) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor:any) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity,marginTop:"10px"}}>
      <img src={src} alt={title} style={{height:"120px",width:"120px"}} />
    </div>
  );
};

export const StandardImageList = ({equipDetails,handleModalClose,newData,}: any) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const { classes } = useStyle();
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const handleCloseCancel = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (equipmentImageId:any) => {
    setLoading(true);
    const reqData = {id:equipmentImageId};
   
    return await deleteImage(reqData).then((res: any) => {
      if (res.response?.status === 500) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('anerroroccuredpleasetryagainlater') as string)
      }
      if (res.response?.status === 400) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('operationfailed') as string)
      }
      if (res.response?.status === 204) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('nodata') as string)
      }
      if (res.response?.status === 403) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('the operation is not allowed, due to insufficient rights') as string)
      }
      if (res.response?.status === 401) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('your session has expired') as string)
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        handleModalClose(true);
        toast.success(t('deleted successfully') as string);
        
      }
    });
  };

  const ig: any = [];
  const [images, setImages] = React.useState(ig);
  equipDetails?.images?.map((r: any) => {
    return r.sizeString === "Small" ? ig.push(r) : <></>;
  });
  const moveImage = React.useCallback((dragIndex:any, hoverIndex:any) => {
    setImages((prevCards:any) => {
      const clonedCards = [...prevCards];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];

      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  if (equipDetails?.images?.length < 1 || equipDetails?.images === undefined) {
    return (
      <UploadImages
        id={equipDetails?.objectId}
        handleModalClose={handleCloseUpload}
      />
    );
  }
  return  equipDetails.images ? (
    <div>
      <div style={{display:'flex', justifyContent:'center', paddingTop:'16px'}}>
        <h5 className={classes.title}>Existing Images</h5>
      </div>
      <Grid container display={'flex'} m={0} p={2} justifyContent={'center'}>
        {
          images.map((image:any, index:any) => (
            <Grid item xs={2} sm={3} md={3} key={image.equipmentImageId}>
                <Button  style={{paddingLeft:"70%"}} color="error" >
                      <ClearIcon onClick={() => handleDelete(image.equipmentImageId)} style={{paddingLeft: "30%",color:"red"}} />
                      <Dialog
                        open={openDelete}
                        onClose={handleCloseCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Delete this equipment?"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this image?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseCancel}>Cancel</Button>
                          <Button onClick={() => handleDelete(image.equipmentImageId)} disabled={loading}>
                            OK {loading && <CircularProgress size={14} />}
                          </Button>
                        </DialogActions>
                      </Dialog>
                </Button>
                <Card
                  src={image.imageUrl}
                  key={image.equipmentImageId}
                  id={image.equipmentImageId}
                  index={index}
                  moveImage={moveImage}
                />
            </Grid>
          ))
        }
      </Grid>
      <Grid>
          <UploadImages
            id={equipDetails?.objectId}
            handleModalClose={handleCloseUpload}
          />
        </Grid>
    
    </div>
  ): (
    <Card sx={{ maxWidth: 500 }}>
      <Grid item>
        <CircularProgress />
        <h5>Please Wait while we fetch images</h5>
      </Grid>
    </Card>
  );
};



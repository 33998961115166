import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import {  postEquipmentDetails } from "../../Services/ApiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Grid, IconButton, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate } from "react-router-dom";
import { useStyle } from "../../styles/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { DropDownList } from "../../hooks/DropDownList";
import { LoaderComponent } from "../LoaderComponent/LoaderComponent";

type UserSubmitForm = {
  name: string;
  description: string;
  telemetryEnabled: boolean;
  idTag: string;
  customerId: string;
  vendorId: string;
  locationId: string;
  subTypeId: string;
  deviceSerialNumber: string;
  operationState: number;
  locationType: number;
};

const Step1 = ({ handleModalClose }: any) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Equipment name is required"),
    description: Yup.string().required("Equipment Description is required"),
    idTag: Yup.string().required("Tag name is required"),
    vendorId: Yup.string().required("Vendor name is required"),
    locationId: Yup.string().required("Location  is required"),
    customerId: Yup.string().required("Customer name is required"),
    subTypeId: Yup.string().required("Type and SubType are required"),
    locationType: Yup.string().required("Location Type  is required"),
    operationState: Yup.string().required("Tank State is required"),
    // telemetryEnabled: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });

  const { register,  handleSubmit,control, watch, formState } = useForm<UserSubmitForm>({ resolver: yupResolver(validationSchema) });

  //   usestate
  const { errors } = formState;
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [selectedVendorValue, setSelectedVendorValue] = useState(null);
  const [selectedStateValue, setSelectedStateValue] = useState(null);
  const [selectedLocationTyValue, setSelectedLocationTyValue] = useState(null);

  const [inputValueState, setStateValue] = useState();
  const [inputValueLocationTy, setLocationTyValue] = useState();
  const [inputValue, setValue] = useState();
  const [inputValueVendor, setVendorValue] = useState();
  const [selectedLocationValue, setSelectedLocationValue] = useState(null);
  const [subType, setSubType] = useState(null);
  let equipmentsDetails: any;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openSubType, setOpenSubType] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [equips, setEquips] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { fetchedDataList, loadingDataList }: any = DropDownList();


  useEffect(() => {
    setLoading(false);
  }, []);
  
  // handle change event of the type dropdown
  const handleTypeChange = (obj: any) => {
    setSubType(null);
    setOpenSubType(false);
    setValue(obj.subTypes);
    setTimeout(() => setOpenSubType(true), 500);
  };

  // handle change event of the subtype dropdown

  const loadOptions = (searchValue: any, callback: any) => {
    callback(inputValue);
  };

  const telemetryEnabled = watch("telemetryEnabled");
  // Dropdownlist fetching
  const fetchDataCustomer = async () => {
    if (!fetchedDataList) return [];
    return await fetchedDataList?.customers
  };
  
  const fetchDataVendor = async () => {
    if (!fetchedDataList) return [];

    return await fetchedDataList?.vendors;
  };

  const fetchDataTypes = async () => { 
    
    if (!fetchedDataList) return [];

    return await fetchedDataList?.types
  };

  const fetchLocationType = async () => { 
    
    if (!fetchedDataList) return [];

    return await fetchedDataList?.locationTypes
  };

  const fetchDataLocation = async (customerId: string) => {
    if (!fetchedDataList) return [];

    return await fetchedDataList?.warehouses.filter((w: any) => w.customerId === customerId);;
  };

  const fetchState = async () => {
    if (!fetchedDataList) return [];
    return await fetchedDataList?.states;
  };
  
  const navigate = useNavigate();
  // handle form changes
  const handleInputChange = (value: any) => {
    setValue(value);
  };
  const handleVendorInputChange = (value: any) => {
    setVendorValue(value);
  };
  const handleLocationInputChange = (value: any) => {
    setVendorValue(value);
  };
  const handleStateInputChange = (value: any) => {
    setStateValue(value);
  };
  const handleLocationTyInputChange = (value: any) => {
    setLocationTyValue(value);
  };
 

  const onSubmit = async (data: UserSubmitForm) => {
    setLoading(true);
    const reqData = { ...data };

    return await postEquipmentDetails(reqData).then((res: any) => {
      equipmentsDetails = res;
      if (res.response?.status === 500) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('anerroroccuredpleasetryagainlater') as string)
      }
      if (res.response?.status === 400) {

        setTimeout(() => { setLoading(false); }, 500);
        handleModalClose(false);
        setErrorMessage(t('operationfailed') as string)
      }
      if (res.response?.status === 204) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('nodata') as string)
      }
      if (res.response?.status === 403) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('theoperationisnotallowedduetoinsufficientrights') as string)
      }
      if (res.response?.status === 401) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('yoursessionhasexpired') as string)
      }
      else {
        setEquips(res);
        setTimeout(() => { setLoading(false); }, 500);
        handleModalClose(true);
        equipmentsDetails = res;
        navigate("/equipment/detail", { state: equipmentsDetails.objectId });
        toast.success(t('submitted successfully') as string);
      }
    });
  };

  const { classes } = useStyle();

  return (
    <div className={classes.registerForm}>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={classes.closeBtn}>
          <h5 className={classes.title}style={{ marginBottom: "20px" }}>
            {t("equipmentdetails")}
          </h5>
          <IconButton style={{ alignItems: "right", justifyContent: "right", marginBottom: "2%", marginLeft:"10%" }} onClick={handleModalClose}>
                <CloseIcon />
          </IconButton>
        </div>

        <Controller control={control}  {...register("name")} render={({ field }) => (
            <TextField
              {...field}
              label={t("equipmentname")}
              sx={{
                "& .MuiOutlinedInput-root": { height: 50 },
                marginTop:"16px"
              }}
              variant="outlined"
              fullWidth
              size="medium"
              margin="none"
            />
          )}
        />
        {errors.name && <p className={classes.errorStyle} > {errors.name.message}</p>}

        <Controller control={control} {...register("description")}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("equipmentdescription")}
              sx={{
                "& .MuiOutlinedInput-root": { height: 50 },
                marginTop:"16px"
              }}
              variant="outlined"
              fullWidth
              size="medium"
              margin="none"
            />
          )}
        />
        {errors.description && (
          <p className={classes.errorStyle}> {errors.description.message}</p>
        )}

        <Controller  {...register("idTag")} control={control} render={({ field }) => (
            <TextField {...field} label={t("idtag")} sx={{"& .MuiOutlinedInput-root": { height: 50 }, marginTop: "16px"}}
              variant="outlined"
              fullWidth
              size="small"
              margin="none"
            />
          )}
        />
        {errors.idTag && (
          <p className={classes.errorStyle}> {errors.idTag.message}</p>
        )}

        <Grid container>

          {
            loadingDataList ? 
              <Grid container display={'flex'} justifyContent={'center'} pb={2}>
                <LoaderComponent/> 
              </Grid>
              :
              <Grid container>
                <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                                
                    const handleSelectChange = (selectedOption: any) => {
                      setSelectedVendorValue(selectedOption);
                      onChange(selectedOption?.id);
                    };
                    return (
                      <div className={classes.dropDownBoxStyle}>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          {...register("vendorId")}
                          value={selectedVendorValue}
                          getOptionLabel={(e: any) => e.name}
                          getOptionValue={(e: any) => e.id}
                          loadOptions={fetchDataVendor}
                          onInputChange={handleVendorInputChange}
                          onChange={handleSelectChange}
                          placeholder={t("vendorname")}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              lineHeight: "1.4375em",
                              height: "55px",
                            }),
                          }}
                        />
                      </div>
                    );
                  }}
                  name="vendorId"
                  rules={{
                    required: true,
                  }}
                />
                {errors.vendorId && (
                  <p className={classes.errorStyle}> {errors.vendorId.message}</p>
                )}

                <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                    const handleSelectChange = (selectedOption: any) => {
                      setSelectedLocationTyValue(selectedOption);
                      onChange(selectedOption?.id);
                    };

                    return (
                      <div className={classes.dropDownBoxStyle}>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          {...register("locationType")}
                          value={selectedLocationTyValue}
                          getOptionLabel={(e: any) => e.name}
                          getOptionValue={(e: any) => e.id}
                          loadOptions={fetchLocationType}
                          onInputChange={handleLocationTyInputChange}
                          onChange={handleSelectChange}
                          placeholder={t("locationtype")}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              lineHeight: "1.4375em",
                              height: "55px",
                            }),
                          }}
                        />
                      </div>
                    );
                  }}
                  name="locationType"
                  rules={{
                    required: true,
                  }}
                />
                {errors.locationType && (
                  <p className={classes.errorStyle}> {errors.locationType.message}</p>
                )}

                <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                    const handleSelectChange = (selectedOption: any) => {
                      setOpenLocation(false);
                      setSelectedValue(selectedOption);
                      onChange(selectedOption?.id);
                      setTimeout(() => setOpenLocation(true), 500);
                    };

                    return (
                      <div className={classes.dropDownBoxStyle}>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          {...register("customerId")}
                          value={selectedValue}
                          getOptionLabel={(e: any) => e.name}
                          getOptionValue={(e: any) => e.id}
                          loadOptions={fetchDataCustomer}
                          onInputChange={handleInputChange}
                          onChange={handleSelectChange}
                          placeholder={t("customername")}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              lineHeight: "1.4375em",
                              height: "55px",
                            }),
                          }}
                          className={classes.inputFields}
                        />
                      </div>
                    );
                  }}
                  name="customerId"
                  rules={{
                    required: true,
                  }}
                />
                {errors.customerId && (
                  <p className={classes.errorStyle}> {errors.customerId.message}</p>
                )}

                {openLocation ? (
                  <Grid item xs={12} md={12} sx={{width:'100%' }}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value, name, ref } }) => {
                        const handleSelectLocation = (selectedOption: any) => {
                          setSelectedLocationValue(selectedOption);
                          onChange(selectedOption?.id);
                        };

                        return (
                          <div className={classes.dropDownBoxStyle}>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              value={selectedLocationValue}
                              getOptionLabel={(e: any) => e.name}
                              getOptionValue={(e: any) => e.id}
                              loadOptions={() => fetchDataLocation(selectedValue?.id)}
                              onInputChange={handleLocationInputChange}
                              onChange={handleSelectLocation}
                              placeholder={t("locationname")}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  lineHeight: "1.4375em",
                                  height: "55px",
                                }),
                              }}
                            />
                          </div>
                        );
                      }}
                      name="locationId"
                      rules={{
                        required: true,
                      }}
                    />
                    {errors.locationId && (
                      <p className={classes.errorStyle}> {errors.locationId.message}</p>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid container sx={{marginTop: "16px" }}>
                  <Grid item xs={openSubType ? 6 : 12}>
                    <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <AsyncSelect
                            cacheOptions
                            loadOptions={fetchDataTypes}
                            {...register("subTypeId")}
                            getOptionLabel={(e: any) => `${t(e.name)}`}
                            getOptionValue={(e: any) => e.id}
                            onChange={handleTypeChange}
                            defaultOptions
                            placeholder={t("type")}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "55px",
                              }),
                            }}
                            className={classes.inputFields}
                          />
                        );
                      }}
                      name="subTypeId"
                      rules={{
                        required: true,
                      }}
                    />
                  </Grid>
                  {openSubType ? (
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                          const handleChangeSubType = (subType: any) => {
                            setSubType(subType);
                            onChange(subType?.id);
                          };

                          return (
                            <AsyncSelect
                              defaultOptions
                              value={ subType }
                              loadOptions={loadOptions}
                              onChange={handleChangeSubType}
                              getOptionLabel={(e: any) => e.name}
                              getOptionValue={(e: any) => e.id}
                              placeholder={t("subtype")}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  height: "55px",
                                  marginLeft: "5%",
                                }),
                              }}
                              className={classes.inputFields}
                            />
                          );
                        }}
                        name="subTypeId"
                      />
                    </Grid>
                  ) : ( <></>
                  )}
                </Grid>
                
                {errors.subTypeId && (
                  <p className={classes.errorStyle}> {errors.subTypeId.message}</p>
                )}

                <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                    const handleSelectChange = (selectedOption: any) => {
                      setSelectedStateValue(selectedOption);
                      onChange(selectedOption?.id);
                    };

                    return (
                      <div className={classes.dropDownBoxStyle}>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          {...register("operationState")}
                          value={selectedStateValue}
                          getOptionLabel={(e: any) => `${t(e.name)}`}
                          getOptionValue={(e: any) => e.id}
                          loadOptions={fetchState}
                          onInputChange={handleStateInputChange}
                          onChange={handleSelectChange}
                          placeholder={t("tankstate")}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              lineHeight: "1.4375em",
                              height: "55px",
                            }),
                          }}
                          className={classes.inputFields}
                        />
                      </div>
                    );
                  }}
                  name="operationState"
                  rules={{
                    required: true,
                  }}
                />
                <div style={{ color: "red" }}>{errors.operationState?.message}</div>
              </Grid>
           }
        </Grid>
        <br />
        <div className="form-group form-check">
          <input type="checkbox" {...register("telemetryEnabled")}  className={`form-check-input ${errors.telemetryEnabled ? "is-invalid" : "" }`}
          />
          <label htmlFor="acceptTerms" className="form-check-label">
            Telementry Enabled
          </label>
          <div className="invalid-feedback">
            {errors.telemetryEnabled?.message}
          </div>
        </div>

        {telemetryEnabled && (
          <Controller
            control={control}
            {...register("deviceSerialNumber")}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("deviceSerialNumber")}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "black" },
                    height: "55px",

                  },
                }}
                variant="outlined"
                fullWidth
                inputProps={{
                  minLength: 7,
                }}
                size="small"
                margin="normal"
                required
              />
            )}
          />
        )}
        {errors.deviceSerialNumber && (
          <span style={{ color: "red" }}>
            You need to enter at least 5 characters!
          </span>
        )}

        <div className={classes.formErr}>
          <div className={classes.formBtn}>
            <Button
              onClick={handleSubmit(onSubmit)}
              style={{
                color: "white",
                backgroundColor: "#00005a",
                width:"20%",
                marginTop:"5%"
              }}
              variant="contained"
              className="btn btn-primary"
              disabled={loading}
            >
              {" "}
              <Typography
                variant="h6"
                sx={{
                  margin: "0px",
                  fontSize: "0.8rem",
                  textTransform: "capitalize",
                }}
              >
                {t("next")}
              </Typography>{" "}
              {loading && <CircularProgress size={14} />}
            </Button>
          </div>
          {errorMessage && 
                  <div className="error" style={{ color: "red", marginTop: "5%" }}> 
                    <div> {errorMessage}  </div>
                  </div>
          }
        </div>
      </form>
    </div>
  );
};

export default Step1;

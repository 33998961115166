import { Close } from '@mui/icons-material';
import {DialogTitle, IconButton, SwipeableDrawer, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react'
import { useStyle } from '../../styles/stylemenu';
import { EquipmentDetail } from '../EquipmentDetail';
import { useTranslation } from 'react-i18next';
export const TooltipInfo = ({equipDetails}: any ) => { 
  const {classes} = useStyle();
  const [openDrawer, setOpenDrawer] = useState(false);

  const myStyle = {
    fontWeight: 'normal !important',
  }
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:600px)');

  const togglePopUp = () => {
    setOpenDrawer(true);  
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const toggleDrawer =  (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(false);
  };

  interface DialogTitleProps {
      id: string;
      children?: React.ReactNode;
      onClose: () => void;
  }

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
        {children}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
            <Close/>
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  return (
    <div style={myStyle}> 
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipKeyDiv}>
          <h2 className={classes.tooltipKey}>{t("tag/badge")}: </h2> 
        </div>
        <div className={classes.tooltipValueDiv}>
          <h2 className={classes.tooltipValue}>{equipDetails.idTag}</h2>
        </div>
      </div>
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipKeyDiv}>
          <h2 className={classes.tooltipKey}>{t("name")}: </h2> 
        </div>
        <div className={classes.tooltipValueDiv}>
          <h2 className={classes.tooltipValue}>{equipDetails.name}</h2>
        </div>
      </div>
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipKeyDiv}>
          <h2 className={classes.tooltipKey}>{t("assignedlocation")}: </h2> 
        </div>
        <div className={classes.tooltipValueDiv}>
          <h2 className={classes.tooltipValue}>{equipDetails?.assignedLocation?.name}</h2>
        </div>
      </div>
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipKeyDiv}>
          <h2 className={classes.tooltipKey}>{t("status")}: </h2> 
        </div>
        <div className={classes.tooltipValueDiv}>
          <h2 className={classes.tooltipValue}>{`${t(equipDetails?.operationStateString)} `}</h2>
        </div>
      </div>
      {equipDetails?.defaultTelemetry?.sort((x: any, y: any) =>  x?.sortOrder - y?.sortOrder).map((info: any, index: any) => ( 
          <div key={index} className={classes.tooltipContainer}>
            <div className={classes.tooltipKeyDiv}>
              <h2 className={classes.tooltipKey}>{`${t(info?.name)}`}: </h2> 
            </div>
            <div className={classes.tooltipValueDiv}>
              <h2 className={classes.tooltipValue}>{Math.floor(info?.valueNormalised*100) }%</h2>
            </div>
          </div> 
          ))
      }
      
      <div className={classes.tooltipBTNContainer}>
        <div>
          <button onClick={togglePopUp} className={classes.tooltipBTN}>{t("viewtank")}</button>
        </div>
      </div>

      <SwipeableDrawer
          anchor={'right'}
          open={openDrawer}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          classes={{
          paper: clsx(
              !matches ? classes.drawerForm : classes.drawerFormMobile
          ),
          }}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div className={classes.equipmentInfoTitle}>
                <div>
                  <h2 className={classes.equipmentNameTitle}>{equipDetails.name}</h2>
                </div>
            </div>        
          </BootstrapDialogTitle>
        <EquipmentDetail equipmentId={equipDetails.objectId} true/>          
      </SwipeableDrawer>
    </div>
  )
}

import { TileLayer, MapContainer, Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import L, { LatLngBounds } from "leaflet";
import { TooltipInfo } from "../Tooltip";
import { EquipmentMapInfo } from "../../interfaces/EquipmentMapInfo";
import ReactDOMServer from "react-dom/server";
import MapMarker from "../MapMarker/MapMarker";
import { LoaderComponent } from "../LoaderComponent/LoaderComponent";
import * as React from "react";
import { GetMapInfo } from "../../hooks/GetMapInfo";
import { Alert, Grid } from "@mui/material";

const center = { lat: 0, lng: 0 };

const hasAlerts = (equipmentItem: any): boolean => {

  if(!equipmentItem.alerts) {
    return false;
  }

  return Object.entries(equipmentItem.alerts)
  .map(([_, alert]) => alert as any)
  .map(alert => alert.isLow || alert.isLowLow || alert.isHigh || alert.isHighHigh)
  .reduce((alert, current) => alert || current, false);
}

export function MapViewController({ equipments }: any) {

  const [mapCenter, setMapCenter] = React.useState<{maxLat: number, minLat: number, maxLng: number, minLng:number}|null>(null);
  const [currentBounds, setCurrentBounds] = React.useState<LatLngBounds|null>(null);

  React.useEffect(() => {

    if(!!equipments && equipments.length > 0 && !mapCenter) {

      const calculationData = (equipments as any[])/*.filter(x => x.geoCoordinates?.latitude > 0)*/.reduce((sums, equipment) => {

        const currentEquipmentLat = equipment.geoCoordinates?.latitude || 0;
        const currentEquipmentLng = equipment.geoCoordinates?.longitude || 0;

        let maxLat = sums.maxLat;
        let minLat = sums.minLat;
        let maxLng = sums.maxLng;
        let minLng = sums.minLng;

        if(currentEquipmentLat > sums.maxLat) {
          maxLat = currentEquipmentLat;
        }

        if(/*currentEquipmentLat > 0 &&*/ currentEquipmentLat < sums.minLat) {
          minLat = currentEquipmentLat;
        }

        if(currentEquipmentLng > sums.maxLng) {
          maxLng = currentEquipmentLng;
        }

        if(/*currentEquipmentLng > 0 &&*/ currentEquipmentLng < sums.minLng) {
          minLng = currentEquipmentLng
        }

        return { maxLat, minLat, maxLng, minLng };

      }, {maxLat: 0, minLat: 1000, maxLng: 0, minLng: 1000});

      const minLngN = calculationData.minLng - (calculationData.maxLng - calculationData.minLng) * 0.05;
      const maxLngN = calculationData.maxLng + (calculationData.maxLng - calculationData.minLng) * 0.05;
      const minLatN = calculationData.minLat - (calculationData.maxLat - calculationData.minLat) * 0.05;
      const maxLatN = calculationData.maxLat + (calculationData.maxLat - calculationData.minLat) * 0.05;

      setMapCenter({
        maxLat: maxLatN > 90 ? 90 : maxLatN, 
        maxLng: maxLngN > 180 ? 180: maxLngN, 
        minLat: minLatN < -90 ? -90 : minLatN, 
        minLng: minLngN < -180 ? -180 : minLngN});
    }
  },
  [equipments]
  )

  const map = useMapEvents({
    zoom(e) {
      setCurrentBounds(e.target.getBounds());
    },
    move(e) {
      setCurrentBounds(e.target.getBounds());
    }
  });

  React.useEffect(() => {
    if(!!mapCenter && !currentBounds) {
      map.fitBounds([
        [mapCenter.minLat, mapCenter.minLng],
        [mapCenter.maxLat, mapCenter.maxLng]
      ])
    } else if(!!currentBounds) {
      map.fitBounds(currentBounds);
    }
  }, [map, mapCenter, currentBounds])
  
  return <></>

}

export default function Map({equipments}: any) {

  const icon = (percent: number, hasAlarms: boolean, state: string) =>
    L.divIcon({
      className: "custom-icon",
      html: ReactDOMServer.renderToString(<MapMarker percent={percent}  hasAlarms={hasAlarms} disconnected={(state==="Disconnected" || state===null)} />),
      iconAnchor: [25, 50], // icon size  is 50x50, so I attach to the center
  });

  return (
   

    <MapContainer
      style={{ height: "100%" }}
      scrollWheelZoom={true}
    >
      <MapViewController equipments={equipments} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {equipments && equipments?.map((equipmentInformation: EquipmentMapInfo) => (
    
          <Marker
            key={equipmentInformation.objectId}
            position={[equipmentInformation?.geoCoordinates?.latitude ||0, equipmentInformation?.geoCoordinates?.longitude ||0]}
            icon={icon(
              Math.floor(equipmentInformation?.defaultTelemetry.length 
                ? equipmentInformation?.defaultTelemetry?.find((s:any) => s.sortOrder === 0).value/100 
                : equipmentInformation?.defaultTelemetry),
              hasAlerts(equipmentInformation), 
              equipmentInformation.connectionState)}>
            <Popup
              position={[equipmentInformation?.geoCoordinates?.latitude ||0, equipmentInformation?.geoCoordinates?.longitude ||0]}
            >
              <TooltipInfo equipDetails={equipmentInformation} />
            </Popup>
          </Marker>
        ))}
    </MapContainer>
 


     
   
  
    
  );
}

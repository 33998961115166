import { Card, CardContent, CardMedia, Grid, Popover, Typography, useMediaQuery } from '@mui/material'
import { useStyle } from '../../styles/stylemenu'
import noImg from "../../assets/noImg.png";
import { ToastContainer } from 'react-toastify';
import { AccessTime,CircleNotifications, DoDisturbOn, SignalWifi3Bar , SignalWifiStatusbar4Bar, SignalWifi0Bar, SignalWifiOff, Battery90, Battery80, BatteryFull, Battery30, Battery20, BatteryAlert, Battery0Bar } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { BorderLinearProgress } from '../Graphs/BorderLinearProgress';
import { useTranslation } from 'react-i18next';
import timestamp from '../../utils/timestamp';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { clearTemporaryObject } from '../../store/features/Telemetry/telemetrySlice';
import { clearDetailObj } from '../../store/features/Equipment Detail/slice';


export const EquipmentListCard = ({ equipments }: any) => {

 
  const { t } = useTranslation(); 
  const { classes } = useStyle();
  const matches = useMediaQuery('(max-width:600px)');
  const matchesWidth = useMediaQuery('(max-width:1424px)');
  const matchesWidth1 = useMediaQuery('(max-width:1800px)');
  const matchesWidth2 = useMediaQuery('(max-width:2048px)');
  const matchesWidth3 = useMediaQuery('(max-width:2304px)');
  const matchesWidth4 = useMediaQuery('(max-width:3072px)');
  const matchesWidth5 = useMediaQuery('(max-width:4608px)');
  const matchesWidth6 = useMediaQuery('(max-width:6144px)');
  const navigate = useNavigate();
  const BLinearProgress = BorderLinearProgress();
  const dispatch: AppDispatch = useDispatch();


  const handleClick = (evt: any) => {
    dispatch(clearTemporaryObject());
    dispatch(clearDetailObj());
    return (
      navigate('/equipment/detail',  {state: equipments.objectId})
    );
  }

  const preventChildClickEvent = (click: any) => {
    click.stopPropagation();
  }


  const [anchorSignalEl, setSignalAnchorEl] = React.useState<HTMLElement | null>(null);

  const [anchorAlarmEl, setAlarmAnchorEl] = React.useState<HTMLElement | null>(null);  
  
  const [anchorBatteryEl, setBatteryAnchorEl] = React.useState<HTMLElement | null>(null);
    

  const handleBatteryPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setBatteryAnchorEl(event.currentTarget);
  };

  const handleBatteryPopoverClose = () => {
    setBatteryAnchorEl(null);
  };

  const handleSignalPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSignalAnchorEl(event.currentTarget);
  };

  const handleSignalPopoverClose = () => {
    setSignalAnchorEl(null);
  };

  const handleAlarmPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAlarmAnchorEl(event.currentTarget);
  };

  const handleAlarmPopoverClose = () => {
    setAlarmAnchorEl(null);
  };

  const open = Boolean(anchorSignalEl);
  
  const openAlarm = Boolean(anchorAlarmEl);

  const openBattery = Boolean(anchorBatteryEl);

  const HoverComponent = (val: any) => {
    return  <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none'}}
                open={open}
                anchorEl={anchorSignalEl}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{ vertical: 'top', horizontal: 'left'}}
                onClose={handleSignalPopoverClose}
                disableRestoreFocus >
              <Typography sx={{ p: 1 }}>Signal {val?.val?.value} %</Typography>
            </Popover>
  }

  const HoverBatteryComponent = (val: any) => {
    return  <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none'}}
                open={openBattery}
                anchorEl={anchorBatteryEl}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                onClose={handleBatteryPopoverClose}
                disableRestoreFocus >
              <Typography sx={{ p: 1 }}>Battery Level {val?.val?.value} %</Typography>
            </Popover>
  }

  const HoverAlarmComponent = (val: any) => {
    return  <Popover id="mouse-over-popover" sx={{  pointerEvents: 'none' }}
                    open={openAlarm}
                    anchorEl={anchorAlarmEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                    transformOrigin={{ vertical: 'top',horizontal: 'left'}}
                    onClose={handleAlarmPopoverClose}
                    disableRestoreFocus>
              <Typography sx={{ p: 1 }}>Alarm</Typography>
            </Popover>
  }

  return (
    <div style={{width:'100%'}}>
      <Card sx={{ margin: { xs: "10px", md: "30px", lg: "15px" }, borderRadius: "10px", backgroundColor:"rgb(246 246 246)" }}>
        <Grid sx={{ padding: { md: "10px", xs: "0px" } }} item container>
          <Grid onClick={handleClick} className={classes.equipImageCard} sx={{ paddingRight: { md: "20px" }, cursor:"pointer", }} item xs={12} md={3}>
            { equipments.thumbnail === null || equipments.thumbnail === "" ?
              <CardMedia
                component="img"
                sx={{ objectFit:'scale-down', height: { md:'180px', xs: "250px", lg:matchesWidth ? '190px' :  matchesWidth1 ? '200px' : matchesWidth2 ? '240px' : matchesWidth3 ? '250px' : matchesWidth4 ? '350px' : matchesWidth5 ? '550px' : matchesWidth6 ? '700px' :'350px' }, borderRadius: { md: "10px", xs: "0px" } }}
                src={noImg}
                alt={noImg}
                className={classes.equipmentListImage}
              />
              :
              <CardMedia
              component="img"
              sx={{ objectFit:'scale-down', height: { md:'180px', xs: "250px", lg:matchesWidth ? '190px' :  matchesWidth1 ? '200px' : matchesWidth2 ? '240px' : matchesWidth3 ? '250px' : matchesWidth4 ? '350px' : matchesWidth5 ? '550px' : matchesWidth6 ? '700px' :'350px' }, borderRadius: { md: "10px", xs: "0px" } }}
              src={equipments.thumbnail}
              alt={noImg}
              className={classes.equipmentListImage}
            />
            }
           
          </Grid>
          <Grid className={classes.equipDetailCard} item xs={12} md={9} sx={{ padding: { xs: "5px", md: "0px" } }}>
            <CardContent className={classes.equipmentContentCard} sx={{ flex: '1 0 auto' }}>
              <Grid item md={11.5}>
                  {
                    equipments?.operationState === 1 && equipments?.defaultTelemetry &&
                    <Grid pb={2} item md={12} xs={12} display={{xs:'flex', md:'none'}} justifyContent={'space-around'} flexDirection={'column'}>
                      {equipments?.defaultTelemetry && equipments?.defaultTelemetry?.map((res: any, index: any) => (
                        <Grid key={index} item md={5}  pt={2} xs={12}>
                          <Typography component="div" variant='h6'>
                            <Typography variant="h6" sx={{ margin: "0px", color: { xs: "black", md: "black" }, fontSize: { xs: "0.8rem", md: "0.8rem" }, lineHeight: "1.2rem", fontWeight: "bold" }}> {`${t(res.name)} (%)`} {Math.trunc(res.valueNormalised * 100)}</Typography>
                          </Typography>
                          <BLinearProgress value={Math.trunc(res.valueNormalised * 100)} color={(Math.trunc(res.valueNormalised * 100)) < 30 ? 'error' : 'success'} variant="determinate" sx={{ height: "6px" }} />
                        </Grid>
                      ))}
                    </Grid>
                  }
                <Grid className={matches ? classes.cardMobilebtnStyle : classes.cardbtnStyle} container>
                  <Grid item md={3} xs={6}  className={classes.tagNameContainer} >
                    <Typography onClick={(clickFN) => preventChildClickEvent(clickFN)} className={classes.tagName} variant="h6" sx={{ padding: { xs: "8px 20px 8px 20px", md:"5px" }, background:"#dcd9d9cc", borderRadius: { xs: "0px", md: "4px" }, fontSize: { xs: "12px" }, fontWeight: "500" }}>{equipments.idTag}</Typography>
                  </Grid>
                {
                  equipments.operationState === 1 && equipments.defaultTelemetry &&
                  <Grid item md={9} xs={6}  sx={{display: { md: "flex", xs:'none', lg:'flex' }}}  justifyContent={'space-around'} flexDirection={'row'}>
                    {equipments?.defaultTelemetry && equipments?.defaultTelemetry?.map((res: any, index: any) => (
                      <Grid onClick={(clickFN) => preventChildClickEvent(clickFN)} key={index} item md={5} xs={6}>
                        <Typography component="div" variant='h6'>
                          <Typography variant="h6" sx={{ margin: "0px", color: { xs: "black", md: "black" }, fontSize: { xs: "0.8rem", md: "0.8rem" }, lineHeight: "1.2rem", fontWeight: "bold" }}> {`${t(res.name)} (%)`} {Math.trunc(res.valueNormalised * 100)}</Typography>
                        </Typography>
                        <BLinearProgress value={Math.trunc(res.valueNormalised * 100)} color={(Math.trunc(res.valueNormalised * 100)) < 30 ? 'error' : 'success'} variant="determinate" sx={{ height: "6px" }} />
                      </Grid>
                    ))}
                  </Grid>
                  }
                </Grid>
                <Grid direction="row" container p={{ xs: '10px 0px', md: '30px 0px', lg: '35px 0px' }} display={'flex'} flexDirection={'column'} item>
                  <Grid item sx={{cursor:"pointer"}}>
                    <Typography onClick={handleClick} className={classes.equipmentTitleList} variant='h6'>
                      {equipments.name}
                    </Typography>
                  </Grid>

                  <Grid item md={12} display={'flex'} flexDirection={'row'} p={{ xs: '10px 0px', md: '10px 0px 0px 0px' }}>
                      <AccessTime sx={{fontSize:'0.8rem'}}/>
                      <Typography paddingLeft={"10px"} sx={{ overflow: "hidden", color: "#3A3A3A", fontSize: "0.8rem", lineHeight: "1", WebkitLineClamp: "2", display: "-webkit-box", WebkitBoxOrient: "vertical" }} component="h6">
                         {equipments?.lastUpdateTime ? `${t('Updated')}  ${timestamp(new Date(equipments?.lastUpdateTime), t)}` : 'N/A'}
                      </Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.cardMoreInfoContainer} container >
                  <Grid item md={3} xs={7} sx={{ paddingBottom: { xs: "5px" } }}>
                    <Typography sx={{ fontSize: { xs: "14px" }, fontWeight: { xs: "normal", md: "bold" } }} className={classes.equipmentCategoryTitle} component="h6">{t("type")} </Typography>
                    <Typography sx={{ fontSize: { xs: "15px" }, fontWeight: "500" }} className={classes.equipmentCategoryDetails} variant="h6"> {equipments.type} </Typography>
                  </Grid>
                  <Grid item md={3} xs={5}>
                    <Typography sx={{ fontSize: { xs: "14px" }, fontWeight: { xs: "normal", md: "bold" } }} className={classes.equipmentCategoryTitle} component="h6"> {t("status")} </Typography>
                    <Typography sx={{ fontSize: { xs: "15px" }, fontWeight: "500" }} className={classes.equipmentCategoryDetails} variant="h6"> {`${t(equipments.operationStateString)} `} </Typography>
                  </Grid>
                  <Grid item md={3} xs={7}>
                    <Typography sx={{ fontSize: { xs: "14px" }, fontWeight: { xs: "normal", md: "bold" } }} className={classes.equipmentCategoryTitle} component="h6"> {t("vendorname")}</Typography>
                    <Typography sx={{ fontSize: { xs: "15px" }, fontWeight: "500" }} className={classes.equipmentCategoryDetails} variant="h6"> {equipments.vendorName} </Typography>
                  </Grid>
                  <Grid item md={3} xs={5}>
                    <Typography sx={{ fontSize: { xs: "14px" }, fontWeight: { xs: "normal", md: "bold" } }} className={classes.equipmentCategoryTitle} component="h6">{t("location")}</Typography>
                    <Typography sx={{ fontSize: { xs: "15px" }, fontWeight: "500" }} className={classes.equipmentCategoryDetails} variant="h6"> {equipments.assignedLocation?.name} </Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.cardbuttonContainer} item xs={12} md={4} sx={{ marginTop: { xs: "10px", md: "0px" }, display: { md: "none", xs:'block' } }}>
                  <Grid display={'flex'} justifyContent={'space-evenly'} xs={12} item>
                      {
                        equipments?.hasTelemetryAlarm === true  && equipments?.operationState === 1 && equipments?.defaultTelemetry.length ?
                        (<Grid item display={"flex"} justifyContent={"end"}>
                            <Grid>
                            {<HoverAlarmComponent val={equipments}/> }
                              <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleAlarmPopoverOpen} onMouseLeave={handleAlarmPopoverClose}>
                                <CircleNotifications  style={{ color: "red" }} className={classes.notificationIcon} />
                              </div>
                            </Grid>
                        </Grid>) 
                        :
                        equipments?.operationState === 2 ?
                          <Grid item display={"flex"} justifyContent={"end"}>
                            <DoDisturbOn style={{ color: "#ffa519" }} />
                          </Grid>
                        : 
                        (<></>)
                      }

                      {
                      equipments?.operationState === 1 && (
                        <Grid item md={12} xs={0} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                          {equipments?.connectionState === "Connected" && (
                            <Grid item display={"flex"} justifyContent={"end"}>
                              { equipments?.signalStrength?.value >= equipments?.signalStrength?.highLimit && equipments?.signalStrength?.value <= equipments?.signalStrength?.highHighLimit ? 
                                (
                                  <Grid>
                                    <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                      <SignalWifiStatusbar4Bar style={{ color: "green" }} className={classes.notificationIcon} />
                                    </div>
                                  </Grid>
                                ) 
                                :
                                equipments?.signalStrength?.value >= equipments?.signalStrength?.lowLimit && equipments?.signalStrength?.value <= equipments?.signalStrength?.highLimit ? 
                                (
                                  <Grid>
                                    <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                      <SignalWifi3Bar style={{ color: "green" }} className={classes.notificationIcon} />
                                    </div>
                                  </Grid>
                                ) 
                                :
                                equipments?.signalStrength?.value >= equipments?.signalStrength?.lowLowLimit && equipments?.signalStrength?.value <= equipments?.signalStrength?.lowLimit ?
                                (
                                  <Grid>
                                    <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                      <SignalWifi0Bar style={{ color: "red" }} className={classes.notificationIcon} />
                                    </div>
                                  </Grid>
                                ) 
                                :
                                (
                                  <Grid>
                                    <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                      <SignalWifiOff className={classes.notificationIcon} />
                                    </div>
                                  </Grid>
                                )
                              }
                            </Grid>
                          )
                          } 
                          {equipments?.connectionState === "Disconnected" && (
                            <Grid item display={"flex"} justifyContent={"end"}>
                                <Grid>
                                  {<HoverComponent val={equipments?.signalStrength}/> }
                                  <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                    <SignalWifiOff className={classes.notificationIcon} />
                                  </div>
                                </Grid>
                            </Grid>
                          )
                          }
                        </Grid>
                      )}

                      {
                        equipments?.operationState === 1 && (
                          <Grid item md={12} xs={0} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            {equipments?.connectionState === "Connected" && (
                              <Grid item display={"flex"} justifyContent={"end"}>
                                { equipments?.batteryLevel?.value === equipments?.batteryLevel?.highHighLimit ? 
                                  (
                                    <Grid>
                                      <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <BatteryFull style={{ color: "green" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.highLimit && equipments?.batteryLevel?.value < equipments?.batteryLevel?.highHighLimit ? 
                                  (
                                    <Grid>
                                      <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery90 style={{ color: "green" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.lowLimit && equipments?.batteryLevel?.value <= equipments?.batteryLevel?.highLimit ? 
                                  (
                                    <Grid>
                                      <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery80 style={{ color: "green" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.lowLimit ?
                                  (
                                    <Grid>
                                      <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery30 style={{ color: "orange" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.lowLowLimit ?
                                  (
                                    <Grid>
                                      <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery20 style={{ color: "red" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  (
                                    <Grid>
                                      <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery0Bar className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  )
                                }
                              </Grid>
                            )
                            } 
                            {equipments?.connectionState === "Disconnected" && (
                              <Grid item display={"flex"} justifyContent={"end"}>
                                  <Grid>
                                    {<HoverComponent val={equipments?.signalStrength}/> }
                                    <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                      <BatteryAlert style={{ color: "red" }} className={classes.notificationIcon} />
                                    </div>
                                  </Grid>
                              </Grid>
                              )
                            }
                          </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display={'flex'} justifyContent={'space-between'} flexDirection={'column'} md={0.5}>
                <Grid item md={12} xs={0} sx={{ display: { md: "flex", xs: "none" }, justifyContent: "flex-end" }} >
                  {
                    equipments?.hasTelemetryAlarm === true  && equipments?.operationState === 1 && equipments?.defaultTelemetry.length ?
                    (<Grid item display={"flex"} justifyContent={"end"}>
                        <Grid>
                        {<HoverAlarmComponent val={equipments}/> }
                          <div aria-owns={openAlarm ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleAlarmPopoverOpen} onMouseLeave={handleAlarmPopoverClose}>
                            <CircleNotifications  style={{ color: "red" }} className={classes.notificationIcon} />
                           </div>
                        </Grid>
                    </Grid>) 
                    :
                    equipments?.operationState === 2 ?
                      <Grid item display={"flex"} justifyContent={"end"}>
                        <DoDisturbOn style={{ color: "#ffa519" }} />
                      </Grid>
                    : 
                    (<></>)
                  }
                </Grid>
                  {equipments?.operationState === 1 && (
                    <Grid item md={12} xs={0} sx={{ display: { md: "flex", xs: "none" }, justifyContent: "flex-end" }}>
                      {equipments?.connectionState === "Connected" && (
                        <Grid item display={"flex"} justifyContent={"end"}>
                          { equipments?.signalStrength?.value >= equipments?.signalStrength?.highLimit && equipments?.signalStrength?.value <= equipments?.signalStrength?.highHighLimit ? 
                            (
                              <Grid>
                                {<HoverComponent val={equipments?.signalStrength}/> }
                                <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                  <SignalWifiStatusbar4Bar style={{ color: "green" }} className={classes.notificationIcon} />
                                </div>
                              </Grid>
                            ) 
                            :
                            equipments?.signalStrength?.value >= equipments?.signalStrength?.lowLimit && equipments?.signalStrength?.value <= equipments?.signalStrength?.highLimit ? 
                            (
                              <Grid>
                               {<HoverComponent val={equipments?.signalStrength}/> }
                                <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                  <SignalWifi3Bar style={{ color: "green" }} className={classes.notificationIcon} />
                                </div>
                              </Grid>
                            ) 
                            :
                            equipments?.signalStrength?.value >= equipments?.signalStrength?.lowLowLimit && equipments?.signalStrength?.value <= equipments?.signalStrength?.lowLimit ?
                            (
                              <Grid>
                                {<HoverComponent val={equipments?.signalStrength}/> }
                                <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                  <SignalWifi0Bar style={{ color: "red" }} className={classes.notificationIcon} />
                                </div>
                              </Grid>
                            ) 
                            :
                            (
                              <Grid>
                                {<HoverComponent val={equipments?.signalStrength}/> }
                                <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                  <SignalWifiOff className={classes.notificationIcon} />
                                </div>
                              </Grid>
                            )
                          }
                        </Grid>
                      )
                      } 
                      {equipments?.connectionState === "Disconnected" && (
                        <Grid item display={"flex"} justifyContent={"end"}>
                            <Grid>
                              {<HoverComponent val={equipments?.signalStrength}/> }
                              <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleSignalPopoverOpen} onMouseLeave={handleSignalPopoverClose}>
                                <SignalWifiOff className={classes.notificationIcon} />
                              </div>
                            </Grid>
                        </Grid>
                      )
                      }
                    </Grid>
                  )}
                  {
                    equipments?.operationState === 1 &&
                    <Grid item md={12} xs={0} sx={{ display: { md: "flex", xs: "none" }, justifyContent: "flex-end" }}>
                           {equipments?.connectionState === "Connected" && (
                              <Grid item display={"flex"} justifyContent={"end"}>
                                { equipments?.batteryLevel?.value === equipments?.batteryLevel?.highHighLimit ? 
                                  (
                                    <Grid>
                                      {<HoverBatteryComponent val={equipments?.batteryLevel}/> }
                                      <div aria-owns={openBattery ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <BatteryFull style={{ color: "green" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.highLimit && equipments?.batteryLevel?.value < equipments?.batteryLevel?.highHighLimit ? 
                                  (
                                    <Grid>
                                      {<HoverBatteryComponent val={equipments?.batteryLevel}/> }
                                      <div aria-owns={openBattery ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery90 style={{ color: "green" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.lowLimit && equipments?.batteryLevel?.value <= equipments?.batteryLevel?.highLimit ? 
                                  (
                                    <Grid>
                                      {<HoverBatteryComponent val={equipments?.batteryLevel}/> }
                                      <div aria-owns={openBattery ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery80 style={{ color: "green" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.lowLimit ?
                                  (
                                    <Grid>
                                      {<HoverBatteryComponent val={equipments?.batteryLevel}/> }
                                      <div aria-owns={openBattery ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery30 style={{ color: "orange" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  equipments?.batteryLevel?.value >= equipments?.batteryLevel?.lowLowLimit ?
                                  (
                                    <Grid>
                                      {<HoverBatteryComponent val={equipments?.batteryLevel}/> }
                                      <div aria-owns={openBattery ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery20 style={{ color: "red" }} className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  ) 
                                  :
                                  (
                                    <Grid>
                                      {<HoverBatteryComponent val={equipments?.batteryLevel}/> }
                                      <div aria-owns={openBattery ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                        <Battery0Bar className={classes.notificationIcon} />
                                      </div>
                                    </Grid>
                                  )
                                }
                              </Grid>
                              )
                            } 
                            {equipments?.connectionState === "Disconnected" && (
                              <Grid item display={"flex"} justifyContent={"end"}>
                                  {<HoverBatteryComponent val={equipments?.batteryLevel}/> }
                                  <div aria-owns={openBattery ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleBatteryPopoverOpen} onMouseLeave={handleBatteryPopoverClose}>
                                    <BatteryAlert style={{ color: "red" }} className={classes.notificationIcon} />
                                  </div>
                              </Grid>
                              )
                            }
                    </Grid>
                   }
              </Grid>
              <ToastContainer />
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </div>
  );

}

import { AppBar, Drawer, List, useMediaQuery, useTheme } from '@mui/material'

import React, { useState } from 'react'
import { useStyle } from '../../styles/stylemenu'
import clsx from 'clsx';
import MaltheWinjeLogoSM from '../../assets/MW-sm.png';
import MaltheWinjeLogo from '../../assets/MW_white_alone.png';
import { routesAdapter } from '../../configs/routes';
import { MenuItem } from '../MenuItem';
import { useTranslation } from 'react-i18next';
// import {RouterAdapter} from '../../configs/routes'

export const Navigation = ({ open, onOpenChange } : any) => {
  const {classes} = useStyle();
  const matches = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation();
  const closeNavigation = () => { 
    if(matches){
      onOpenChange(!open)
    }
  }

  return (
    <Drawer
      classes={{
        paper: clsx(
          classes.navigationDrawer,
          !open && classes.navigationDrawerCollapse
        ),
      }}
      open={open}
      variant={matches ? "temporary" : "permanent"}
    >
      <div className={classes.navigationLogoContainer}>
        <img
          src={open ? MaltheWinjeLogo : MaltheWinjeLogoSM}
          alt="Malthe Winje"
          className={open ? classes.navigationLogo : classes.navigationLogoMobile}
        />
      </div>
      <List className={classes.navigationList}>
        {routesAdapter().filter((res: any) => {return res.status === true}).map((route, index) => {
          return (
            <React.Fragment key={index}>
              {route.path === "/sign-out" && (
                <div className={classes.navigationSpacer}></div>
              )}
              <MenuItem
                onClick={() => closeNavigation()}
                key={index}
                label={t(route.label)}
                path={route.path}
                icons={route.icon}
              />
            </React.Fragment>
          );
        })}
      </List>
    </Drawer>
  );
}

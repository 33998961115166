import React, { FC} from "react";
import { useStyle } from "../../styles/styles";

import { Button, SwipeableDrawer, useMediaQuery, } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import Step1 from "./step1";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";

const Create: FC = () => {
  const { classes } = useStyle();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleOpen = () => setOpenDrawer(true);
  const handleClose = () => { setOpenDrawer(false) };
  const matches = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation();

  const toggleDrawer =  (event: React.KeyboardEvent | React.MouseEvent) => {
    if ( event && event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(false);
  };

  return (
    <div>
      <Button
        style={{  margin: "10px 15px 0px 0px",  color: "white", backgroundColor: "#00005a",    }}
        onClick={handleOpen}
        variant="outlined"
        color="success"
        sx={{ padding: "0px 8px", textTransform: "capitalize" }}
        endIcon={ <AddCircle/> } >
        {t("newequipment")}
      </Button>

      <SwipeableDrawer
        anchor={'right'}
        open={openDrawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        classes={{
          paper: clsx(
            !matches ? classes.drawerForm : classes.drawerFormMobile
          ),
        }}
      >
         <Step1 handleModalClose={handleClose} />
        
      </SwipeableDrawer>
    </div>
  );
};

export default Create;

import React, { useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AppDispatch } from '../../store/store';
import { useDispatch } from 'react-redux';
import { setUpdatedToFalse } from '../../store/features/Equipment Detail/slice';
import { setNewObjectToFalse, setObjectDeletedStatusFalse } from '../../store/features/Equipment/equipmentSlice';
import { useTranslation } from 'react-i18next';
const Alerts = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const Alert = ({message, added, updated, deleted, id, state}: any) => {
    const [open, setOpen] = useState(state);      
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => { 
        if (reason === 'clickaway') { 
          dispatch(setUpdatedToFalse(id))
          setOpen(false)
          return;
        }
        if(added === false){
          dispatch(setObjectDeletedStatusFalse(false))
        }
        if(updated){
          dispatch(setUpdatedToFalse(id))
          dispatch(setNewObjectToFalse(updated.objectId))
        }
    };


  return (
    <div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal:'right' }} open={state} onClose={handleClose}>
          <Alerts onClose={handleClose} severity={ added || updated ? "success" : "error"} sx={{ width: '100%' }}>
            {added ? t('newitemhasbeenadded') : updated ? t('thisinformationhasbeenupdated') : t('anitemhasbeenremoved')} <br></br>
             { added ? t('pleaserefreshtogetlatestlist') : '' }
          </Alerts>
        </Snackbar>
    </div>
  )
}

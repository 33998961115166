import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import England from "../../../assets/England.png";
import Norway from "../../../assets/norway.png";
import Sweden from "../../../assets/sweden.png";
import { Avatar, Badge, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { ref } from 'yup';
export default function SelectLabels() {
  const [menu, setMenu] = React.useState('no');

  const handleChange = (event: SelectChangeEvent) => {
    setMenu(event.target.value);
  };
  const { t, i18n, ready } = useTranslation();
  const changeLanguage = (lng: any) => { 
    i18n.changeLanguage(lng);
  };
  return (
    <>



      <Tooltip title={t('selectlangauge')} disableInteractive={true}>
        <FormControl sx={{ m: 1, width: '100%', maxWidth: 360 }}>

          <Select
            value={menu}
            onChange={handleChange}
            displayEmpty={false}
    
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },width:"150px" }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
          <MenuItem value="" disabled>
           
          </MenuItem>
            <MenuItem value={"no"} onClick={() => changeLanguage("no")}  >
            <ListItemIcon>
                <img
                  src={Norway}
                  style={{ width: 20, height: 20, }}
                  alt="Norway"
                  loading="lazy"
                />
              </ListItemIcon>
             Norsk

              
            </MenuItem>

            <MenuItem value={"se"} onClick={() => changeLanguage("se")}>
              <ListItemIcon>
                <img
                  src={Sweden}
                  style={{ width: 20, height: 20 }}
                  alt="Sweden"
                  loading="lazy"
                />
              </ListItemIcon>Svenska
              {/* <ListItemText primary="Svenska" /> */}

            </MenuItem>
            <MenuItem value={"en"} onClick={() => changeLanguage("en")}>
              <ListItemIcon>
                <img
                  src={England}
                  style={{ width: 20, height: 20 }}
                  alt="English"
                  loading="lazy"
                />
              </ListItemIcon>English
              {/* <ListItemText primary="English" /> */}

            </MenuItem>
          </Select>

        </FormControl>
      </Tooltip>
    </>
  );
}

import { Grid, Pagination } from '@mui/material';
import { EquipmentListCard } from '../components/EquipmentListCard';
import Create from '../components/EquipementForm/Create';
import { useEffect, useState } from 'react';
import { LoaderComponent } from '../components/LoaderComponent/LoaderComponent';
import NoDataImage from "../assets/noImage.jpg";
import { useLocation } from 'react-router-dom';
import { getAccess } from '../Services/ApiService';
import { AppDispatch, RootState } from '../store/store';
import { PageParam, getObjects } from '../store/features/Equipment/equipmentAction';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { Error } from '../components/Error/Error';
import { Alert } from '../components/Alerts/Alert';
import { Button } from '../components/Button/button';

export const Equipment = () => {
  const [perPage, setPerPage] = useState(5);
  const location = useLocation();
  const state = location.state; // Access the state
  const [retryCount, setRetryCount] = useState(0);
  const maxRetryAttempts = 3;
  const retryInterval = 10000;
  const dispatch: AppDispatch = useAppDispatch();
  const objectsList = useAppSelector((stateS: RootState) => stateS?.objects);
  const loading = useAppSelector((stateS: RootState) => stateS?.objects?.loading);
  const error = useAppSelector((stateS: RootState) => stateS?.objects?.error);
  const newObject = useAppSelector((stateS: RootState) => stateS?.objects?.newObject);
  const [customerRoles, setCustomerRoles] = useState([]);
  const { data, currentPage, totalPages } = useAppSelector((state: RootState) => state.objects);
  const deletedObject = useAppSelector((stateS: RootState) => stateS?.objects?.deleted);

  const pgParam:PageParam = {pageSize: perPage, pageNumber: currentPage, bypassCache: true};

  const handlePageChange = (event: any, value: number) => { 
    pgParam.pageSize = perPage;
    pgParam.pageNumber = value;
    dispatch(getObjects(pgParam))
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getObjects({ pageNumber: pgParam.pageNumber, pageSize: pgParam.pageSize, bypassCache: true }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

    if (error && retryCount < maxRetryAttempts) {
      const timerId = setTimeout(() => {
        setRetryCount(retryCount + 1);
      }, retryInterval);
      return () => clearTimeout(timerId);
    }

    if (state && state.err) {
      location.state = null;
    }
  }, []);


  const noDetailsDataFound = () => { }
  if (loading) {
    <LoaderComponent />
  }
  const appRoles = async () => {
        
    const access = await getAccess();

    const customerAccess = access.customers.map((c:any) => ({id: c.id, roles: c.roles}));

    setCustomerRoles(customerAccess);

  };


  useEffect(() => {appRoles()}, []);

  const canEdit = () => {
    
    if(!objectsList) {
        return false;
    }

    if(!customerRoles) {
        return false;
    }

    const customerRole:any = customerRoles.find((c:any) => c.id);
    if(!customerRole){
        return false;
    }

    const writerRole = customerRole.roles?.find((r:string) => r.includes("writer"))
    
    return !!writerRole;
  };

  if(loading){
    return (
    <Grid item pt={5} display={'flex'} justifyContent={'center'} flexDirection={'row'} height={'100%'}>
      <LoaderComponent/>
    </Grid>
    )
  }
  return <div>
    
    {
      error ? <Error isOpen = {true} message = {error} noDetailsDataFound={noDetailsDataFound}/> : ''
    }
    {
      newObject || deletedObject  ? <Alert added={deletedObject ? false : true} updated={newObject} delete={deletedObject ? true : false} state={true} /> : ''
    }

    {
      Object.keys(objectsList.data).length > 0 ?
        <Grid item md={12} sm={12} xs={12}>
          { canEdit()? 
            <Grid container display={"flex"} justifyContent={"flex-end"}>
              <Create />
            </Grid>:<></>
          }
          <Grid container height={"100%"}>
            <Grid container justifyContent={'center'} pt={2}>
              <div>
                <Pagination count={totalPages} page={objectsList.currentPage} onChange={handlePageChange} />
              </div>
            </Grid>
            {
              data && data[currentPage]?.map((res: any) => (
                <EquipmentListCard key={res.objectId} equipments={res} />
              ))
            }
          </Grid>
        </Grid>
        :
        <Grid display={"block"} justifyContent={"center"} flexDirection={"column"} item md={12} sm={12} xs={12}>
          <img height="70%" width={"100%"} src={NoDataImage} alt='No Data' />
        </Grid>
    }
  </div>

}


import { Height } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

export const useStyle = makeStyles()({
container: {
minheight:"100vh",
// padding:"0 0.5rem",
display:"flex",
flexDirection:"column",
justifyContent:"center",
alignItems:"center"
},

main:{
  flex:"1",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center",
  width:"100%"
},
 title:{
  color:"#494949",
  fontFamily:"Roboto, Helvetica, Arial, sans-serif", 
  fontSize:"2rem",
  lineHeight:"1.15",
  margin:"0px"
 },
 title2:{
  color:"#0070f3",
  fontSize:"2rem",
  marginBottom:1

 },
description :{
  lineHeight:"1.5",
  fontSize:"1.5rem"
},
grid :{
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  flexWrap:"wrap",
  maxWidth:800,
  marginTop:"3rem"
},

card:{
  margin:"1rem",
  flexBasis:"45%",
  padding:"1.5rem",
  textAlign:"left",
  color:"inherit",
  textDecoration:"none",
  border:"1px solid #eaeaea",
  borderRadius:"10px",
  transition:"color 0.15s ease,border-color 0.15s ease"
},

registerForm:{
  padding:"16px"
},

inputFields:{
  // marginTop:"12px",
  marginBottom:"0px",
  borderColor: "black"
  
},
editBtnStyle:{
  display:"flex",
  justifyContent:"center",
  flexDirection:"column"
},

formstyle:{
  position: "absolute" as "absolute",
  top: "50%",
  left: "88%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: "24",
},

styleMobile: {
  position: "absolute" as "absolute",
  top: "67%",
  left: "50%",
  right: "0",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: "24",
  
},

styleMobileForm:{
  position: "absolute" as "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  backgroundColor:"white",
  border: "2px solid #00005a",
  boxShadow: "24",
  height:"100%",
},

styleForm: {
  position: "absolute" as "absolute",
  top: "50%",
  left: "85%",
  height:"100%",
  right: "0",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  backgroundColor:"white"
},

dropDownBoxStyle:{
  width:'100%',
  marginTop: '16px'
},

errorStyle:{
  color: 'red',
  margin: '4px'
},

formBtn:{
  width:"100%",
  display:"flex",
  justifyContent:"center",
  flexDirection:"row"
},

formErr:{
  width:"100%",
  display:"flex",
  justifyContent:"center",
  flexDirection:"column"
},
drawerForm:{
  width:'30%'
},
drawerFormMobile:{
  width:'100%'
},
closeBtn:{
  display:'flex',
  width:"100%",
  justifyContent:"space-around"
}

});
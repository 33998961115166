import { createSlice } from "@reduxjs/toolkit";
import { getObjects } from "./equipmentAction";

interface ObjectState {
  data: { [pageNumber: number]: any[] };
  currentPage: number;
  totalPages: number;
  loading: boolean,
  error: boolean,
  newObject: any,
  deleted: any
}

const initialState: ObjectState = {
  data: {},
  currentPage: 1,
  totalPages: 1,
  loading: false,
  error: false,
  newObject: false,
  deleted: false
};

const MAX_OBJECTS_PER_PAGE = 5;

export const objectSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {
    setToFalseNewObject: (state, action) => {
      if(state.newObject && state.newObject.objectId === action.payload){ 
        state.newObject = false;
      }
    },

    setToFalseDeleted: (state, action) => {
        state.deleted = false;
    },

    addObject: (state, action) => {
      const object  = action.payload;
      state.newObject = object;

      // console.log(action.payload)
      // console.log(object)
      // let currentPage = 1;

      // // Check if the object already exists in the first page's array
      // const objectIndex = state.data[1].findIndex((item) => item.objectId === object.objectId);

      // console.log(objectIndex)

      // if (objectIndex !== -1) {
      //   // If the object exists, replace it with the new object
      //   state.data[1][objectIndex] = object;
      // } else {
      //   // If the object doesn't exist, add it to the first page
      //   state.data[1].unshift(object);

      //     // Add the new object to the first page
      //     // state.data[1] = [object, ...state.data[1]];

      //     // Loop through the pages to maintain circular nature
      //     while (state.data[currentPage].length > MAX_OBJECTS_PER_PAGE) {
      //       const nextData = state.data[currentPage + 1] || [];

      //       // Move the last object from the current page to the next page
      //       nextData.unshift(state.data[currentPage].pop());
      //       state.data[currentPage + 1] = nextData;

      //       // Update currentPage and totalPages if necessary
      //       if (currentPage + 1 > state.totalPages) {
      //         state.totalPages = currentPage + 1;
      //       }

      //       // Move to the next page
      //       // currentPage += 1;
      //       // if (currentPage > MAX_PAGES) {
      //       //   currentPage = 1; // Loop back to the first page if necessary
      //       // }
      //     }
      //   }

      // // Update the currentPage
      // state.currentPage = currentPage;
  },

    updateObject: (state, action) => {
      const { objectId } = action.payload;
      // Iterate through each page's data
      for (const pageNumber in state.data) {
        if (state.data.hasOwnProperty(pageNumber)) {
          const targetPageData = state.data[pageNumber];
          
          // Find the index of the object with the matching ID on the current page
          const existingObjectIndex = targetPageData.findIndex((obj) => obj.objectId === objectId);
          
          if (existingObjectIndex !== -1) {
            // Update the existing object with the new data
            targetPageData[existingObjectIndex] = { ...targetPageData[existingObjectIndex], ...action.payload };
            return; // Exit the loop once the object is found and updated
          }
        }
      }

      // for (const page in state.data) {
      //   if (Object.hasOwnProperty.call(state.data, page)) {
      //     state.data[page] = state.data[page].map((object) =>
      //       object.objectId === objectId ? action.payload : object
      //     );
      //   }
      // }
    
    },
    removeObject: (state, action) => {
      const objectIdToRemove = action.payload;
      state.deleted = true;
      // Loop through each page
      for (const pageNum in state.data) {
        if (state.data.hasOwnProperty(pageNum)) {
          const page = state.data[pageNum];
          const objectIndex = page.findIndex((obj) => obj?.objectId === objectIdToRemove);
    
          // Check if the object with the given id exists on this page
          if (objectIndex !== -1) {
            // Remove the object from the page
            page.splice(objectIndex, 1);
    
            // Check if the page is empty after removal, and if so, delete the page
            if (page.length === 0) {
              delete state.data[pageNum];
            }
    
            // Break the loop as we found and removed the object
            break;
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getObjects.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getObjects.fulfilled, (state, action) => {
        if (state.loading === true) {
          state.loading = false;
          const { pageNumber, data, totalPages}: any = action.payload;
          state.data[pageNumber] = data; 
          state.totalPages = totalPages;
          state.currentPage = pageNumber;
        }
      })
      .addCase(getObjects.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const setObjects  = objectSlice?.actions?.addObject;
export const setNewObjectToFalse  = objectSlice?.actions?.setToFalseNewObject;
export const updateObject  = objectSlice?.actions?.updateObject;
export const rmObject = objectSlice?.actions?.removeObject;
export const setObjectDeletedStatusFalse =  objectSlice?.actions?.setToFalseDeleted;
export const { addObject } = objectSlice?.actions;
export default objectSlice.reducer;